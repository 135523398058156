/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'

const InfoAriana2: FC = () => {
  const users = [
    {
      
    },
    
  ]

  return (
    <div className='modal fade' id='kt_modal_info_ariana2' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Canto da InfoAriana2</h1>

              <div className='text-muted fw-bold fs-5'>
              vInfoariana
                <a href='#' className='link-primary fw-bolder'>
                  {' e  '}
                  InfoAriana2
                </a>
                .
              </div>
            </div>

            

            <div className='separator d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>Canto da InfoAriana1</span>
            </div>
            <div
            className='bgi-no-repeat bgi-position-center bgi-size-cover h-350px h-md-auto h-lg-650px  mw-100 w-650px mx-auto'
            style={{
              backgroundPosition: '90% 50%',
              backgroundImage: `url('${toAbsoluteUrl('/media/pdf/lanca_vermelha1.jpg')}')`,
            }}
          ></div>

                       
            
          </div>
        </div>
      </div>
    </div>
  )
}

export {InfoAriana2}