import {FC} from 'react'

type Props = {
  mediunidade?: string
}

const MediumMediumshipCell: FC<Props> = ({mediunidade}) => (
  <div className='badge badge-light fw-bolder'>{mediunidade}</div>
)

export {MediumMediumshipCell}
