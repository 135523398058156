import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { TemplosListWrapper } from './users-list/TemplosList'
import { DevasListWrapper } from './users-list/DevasList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Sala dos Devvas',
    path: '/app/management/devas',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SalaDevasPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='escala'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Escala</PageTitle>
              <DevasListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='escala' />} />
    </Routes>
  )
}

export default SalaDevasPage
