/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
  isEmpty,
} from '../../../../../../_metronic/helpers'
import {getMediums} from './_requests'
import {Medium} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import { useAuth } from '../../../../auth'
import { AuthorizedFunction } from '../../../../auth/components/AuthorizedFunction'

const QueryResponseContext = createResponseContext<Medium>(initialQueryResponse)
const QueryResponseUsersProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const is_admin = AuthorizedFunction(['admin-sistema']);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.MEDIUMS_LIST}-${query}`,
    () => {
      // permite listar os resultados somente a partir do campo de busca preenchido
      if (isEmpty(query)){
        return Promise.reject();
      }

      return getMediums(query, localStorage.getItem('key_id_templo') || '', is_admin ? 1   : 0)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isEmpty(query) ? false : isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseUsersProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
