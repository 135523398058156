import {TemplosListViewProvider, useListView} from './core/TemplosListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseTemplosProvider, useQueryResponse} from './core/QueryResponseTemplosProvider'
import {TemplosListHeader} from './components/header/TemplosListHeader'
import {UserEditModal} from './user-edit-modal/UserEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import { TemplosTable } from './table/TemplosTable'
import { useNavigate } from 'react-router-dom'

const TemplosList = () => {

  let path = `/app/enroll/templo/00000/novo`; 

  return (
    <>
      <KTCard>
        <TemplosListHeader termo='templo' add_path={path} />
        <TemplosTable />
      </KTCard>
    </>
  )
}

const TemplosListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseTemplosProvider>
      <TemplosListViewProvider>
        <TemplosList />
      </TemplosListViewProvider>
    </QueryResponseTemplosProvider>
  </QueryRequestProvider>
)

export {TemplosListWrapper}
