import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {ActivityDrawer, 
                        AdjuntoAdeja, AdjuntoUruata, AdjuntoMuyata,  AdjuntoUruame,  AdjuntoJaguaru, 
                        AdjuntoAruana, AdjuntoJanara, AdjuntoYucata,  AdjuntoYumata,  AdjuntoAluxa, 
                        AdjuntoAmaya, AdjuntoUmayta, AdjuntoTapua,  AdjuntoYpuena,  AdjuntoMarabo,
                        AdjuntoCayra, AdjuntoJalero, AdjuntoYtupura,  AdjuntoMagano,  AdjuntoJapuacy,
                        AdjuntoJapuara,  
    DrawerMessenger, EscalarDevas, 
    InviteUsers2, InviteUsers3, InviteUsers4, InviteUsers5, InviteUsers6,
    InfoNityama1, InfoNityama2, InfoNityama3, InfoNityama4,
    InfoSamaritana1, InfoSamaritana2, InfoSamaritana3, InfoSamaritana4,
    InfoGrega1, InfoGrega2, InfoGrega3, InfoGrega4, 
    InfoMaya1, InfoMaya2, InfoMaya3, InfoMaya4,
    InfoMago1, InfoMago2, InfoMago3, InfoMago4, 
    InfoPrincipe1, InfoPrincipe2, InfoPrincipe3, InfoPrincipe4,
    InfoYuricysol1, InfoYuricysol2, InfoYuricysol3, InfoYuricysol4,
    InfoYuricylua1, InfoYuricylua2, InfoYuricylua3, InfoYuricylua4,
    InfoDharmanoxinto1, InfoDharmanoxinto2, InfoDharmanoxinto3, InfoDharmanoxinto4,
    InfoMuruaicy1, InfoMuruaicy2, InfoMuruaicy3, InfoMuruaicy4, 
    InfoJacana1, InfoJacana2, InfoJacana3, InfoJacana4,
    InfoAriana1, InfoAriana2, InfoAriana3, InfoAriana4,
    InfoMadalena1, InfoMadalena2, InfoMadalena3, InfoMadalena4,
    InfoFranciscana1, InfoFranciscana2, InfoFranciscana3, InfoFranciscana4,
    InfoNarayama1, InfoNarayama2, InfoNarayama3, InfoNarayama4,
    InfoRochana1, InfoRochana2, InfoRochana3, InfoRochana4,
    InfoCaycara1, InfoCaycara2, InfoCaycara3, InfoCaycara4,
    InfoTupinamba1, InfoTupinamba2, InfoTupinamba3, InfoTupinamba4,
    InfoCiganaaganara1, InfoCiganaaganara2, InfoCiganaaganara3, InfoCiganaaganara4,
    InfoCiganatagana1, InfoCiganatagana2, InfoCiganatagana3, InfoCiganatagana4,
    InfoAgulhaismenia1, InfoAgulhaismenia2, InfoAgulhaismenia3, InfoAgulhaismenia4,
    InfoNyatra1, InfoNyatra2, InfoNyatra3, InfoNyatra4,


     ThemeModeProvider, UpgradePlan,} from '../partials'
import {MenuComponent} from '../assets/ts/components'

const MasterLayout = () => {  
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root'>
          {/* begin::Page */}
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            {/* begin::Wrapper */}
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />

              <Toolbar />

              {/* begin::Content */}
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              {/* end::Content */}
              <Footer />
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Page */}
        </div>
        

        {/* begin:: Drawers */}
        <ActivityDrawer />
        

        <AdjuntoAdeja />        
        <AdjuntoUruata />
        <AdjuntoMuyata />
        <AdjuntoUruame />
        <AdjuntoJaguaru />
        <AdjuntoAruana />
        <AdjuntoJanara />
        <AdjuntoYucata />        
        <AdjuntoYumata />
        <AdjuntoAluxa />
        <AdjuntoAmaya />
        <AdjuntoUmayta />
        <AdjuntoTapua />
        <AdjuntoYpuena />
        <AdjuntoMarabo />
        <AdjuntoCayra />
        <AdjuntoJalero />
        <AdjuntoYtupura />
        <AdjuntoMagano />
        <AdjuntoJapuacy />
        <AdjuntoJapuara />        

        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <EscalarDevas />
        <InviteUsers2 />
        <InviteUsers3 />
        <InviteUsers4 />
        <InviteUsers5 />
        <InviteUsers6 />

        <InfoNityama1 />
        <InfoNityama2 />
        <InfoNityama3 />
        <InfoNityama4 />

        <InfoSamaritana1 />
        <InfoSamaritana2 />
        <InfoSamaritana3 />
        <InfoSamaritana4 />

        <InfoGrega1 />
        <InfoGrega2 />
        <InfoGrega3 />
        <InfoGrega4 />

        <InfoMaya1 />
        <InfoMaya2 />
        <InfoMaya3 />
        <InfoMaya4 />

        <InfoMago1 />
        <InfoMago2 />
        <InfoMago3 />
        <InfoMago4 />

        <InfoPrincipe1 />
        <InfoPrincipe2 />
        <InfoPrincipe3 />
        <InfoPrincipe4 />


        <InfoYuricysol1 />
        <InfoYuricysol2 />
        <InfoYuricysol3 />
        <InfoYuricysol4 />

        <InfoYuricylua1 />
        <InfoYuricylua2 />
        <InfoYuricylua3 />
        <InfoYuricylua4 />

        <InfoDharmanoxinto1 />
        <InfoDharmanoxinto2 />
        <InfoDharmanoxinto3 />
        <InfoDharmanoxinto4 />

        <InfoMuruaicy1 />
        <InfoMuruaicy2 />
        <InfoMuruaicy3 />
        <InfoMuruaicy4 />

        <InfoJacana1 />
        <InfoJacana2 />
        <InfoJacana3 />
        <InfoJacana4 />

        <InfoAriana1 />
        <InfoAriana2 />
        <InfoAriana3 />
        <InfoAriana4 />

        <InfoMadalena1 />
        <InfoMadalena2 />
        <InfoMadalena3 />
        <InfoMadalena4 />

        <InfoFranciscana1 />
        <InfoFranciscana2 />
        <InfoFranciscana3 />
        <InfoFranciscana4 />

        <InfoNarayama1 />
        <InfoNarayama2 />
        <InfoNarayama3 />
        <InfoNarayama4 />

        <InfoRochana1 />
        <InfoRochana2 />
        <InfoRochana3 />
        <InfoRochana4 />

        <InfoCaycara1 />
        <InfoCaycara2 />
        <InfoCaycara3 />
        <InfoCaycara4 />

        <InfoTupinamba1 />
        <InfoTupinamba2 />
        <InfoTupinamba3 />
        <InfoTupinamba4 />

        <InfoCiganaaganara1 />
        <InfoCiganaaganara2 />
        <InfoCiganaaganara3 />
        <InfoCiganaaganara4 />

        <InfoCiganatagana1 />
        <InfoCiganatagana2 />
        <InfoCiganatagana3 />
        <InfoCiganatagana4 />

        <InfoAgulhaismenia1 />
        <InfoAgulhaismenia2 />
        <InfoAgulhaismenia3 />
        <InfoAgulhaismenia4 />

        <InfoNyatra1 />
        <InfoNyatra2 />
        <InfoNyatra3 />
        <InfoNyatra4 />

        

        <UpgradePlan />
        
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
