import React, { useState } from 'react'
import {DadosGeraisForm} from './cards/DadosGeraisForm'
import {MentoresForm} from './cards/MentoresForm'
import {IniciacaoForm} from './cards/IniciacaoForm'
import {ElevacaoForm} from './cards/ElevacaoForm'
import {CenturiaForm} from './cards/CenturiaForm'
import {ObservacoesForm} from './cards/ObservacoesForm'
import {initialMedium as initialValues,  Medium, Templo } from '../../../app/management/users-list/core/_models'
import { AuditInformation } from './cards/AuditInformation'

import { isNotEmpty } from '../../../../../_metronic/helpers'
import { SetimoForm } from './cards/SetimoForm'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { DadosGeraisTemploForm } from './cards/DadosGeraisTemploForm'
import { DadosGeraisTemploFormNew } from './cards/DadosGeraisTemploFormNew'

export function TemploForm(templo: Templo) {

  return (
     isNotEmpty(templo.id_templo) ?
      <>
        <DadosGeraisTemploForm templo={templo} />
      </>
    : <><DadosGeraisTemploFormNew /></>
)}
