import React from 'react';
import { toAbsoluteUrl } from '../../../../helpers';
import { TemplateEmissao } from '../../../../../app/modules/app/management/users-list/core/_models';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getSimpleMediumById } from '../../../../../app/modules/app/management/users-list/core/_requests';

const SPACED_DATE_FORMAT = "DD/MM/YYYY";

type Props = {
  className: string;
  template: TemplateEmissao;
  vars_prefixo: object;
  vars_emissao: object;
  formatter: any;
};

const EmissaoWidget: React.FC<Props> = ({ className, template, vars_prefixo, vars_emissao, formatter }) => {

  const { keycloak } = useKeycloak();
  var id_user = localStorage.getItem('key_id_medium_login');

  const {
    data: devas,
  } = useQuery(
    `devas-${id_user}`,
    () => {
      if (!id_user) {
        return null;
      }
      return getSimpleMediumById(Number.isNaN(id_user) ? 0 : parseInt(id_user));
    },
    {
      onError: (err) => {
        console.error(err)
      },
    }
  );

  return (
    <div className={`card card-flush ${className}`} style={{ maxWidth: '210mm', margin: '30px auto', border: '1px solid rgba(0, 0, 0, 0.2)', padding: '15px' }}>
      {/* CABECA EMISSAO */}
      <div className='card-header justify-content-center cursor-pointer' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', marginBottom: '20px' }}>
        <div className='d-flex align-items-center justify-content-between' style={{ maxWidth: '190mm', margin: 'auto' }}>
          <div>
            <img className='h-60px' style={{ marginRight: '15px' }} src={toAbsoluteUrl('/media/logos/devas.png')} alt='Devas' />
          </div>
          <div className='mr-4' style={{ maxWidth: '90%' }}>
            {/* Título da ORDEM */}
            <h3 className='fw-bolder m-0' style={{ marginLeft: 'auto', textAlign: 'center' }}>
              OSOEC - OBRAS SOCIAIS DA ORDEM ESPIRITUALISTA CRISTÃ
            </h3>
             {/* Título da Emissão */}
             <h3 className='m-0 mt-2' style={{ textAlign: 'center', fontWeight: 'normal' }}>
              {template?.nome_template}
            </h3>
          </div>
          <div>
            <img className='h-60px' style={{ marginLeft: '15px' }} src={toAbsoluteUrl('/media/logos/osoec-logo-rounded.png')} alt='Logo' />
          </div>
        </div>
      </div>
  
      {/* PREFIXO */}
      <div className='card-body py-1 fv-row text-justify' style={{ maxWidth: '190mm', margin: 'auto', textAlign:'justify' }}>
        <span className='fw-muted fs-4'>
          {formatter(template?.prefixo_emissao + '', vars_prefixo)}
        </span>
      </div>

      {/* CORPO EMISSAO */}
      <div className='card-body py-1 fv-row text-justify' style={{ maxWidth: '190mm', margin: 'auto', textAlign:'justify' }}>
        <span className='fw-muted fs-4'>
          {formatter(template?.emissao + '', vars_emissao)}
        </span>
        <span className='fw-muted fs-4 text-center'><p>SALVE DEUS!</p></span>
      </div>

      {/* Separador entre Corpo e Rodapé */}
      <hr style={{ margin: '20px 0', border: '1px solid rgba(0, 0, 0, 0.2)' }} />

      {/* Observações */}
      <div className='card-footer py-1 fv-row text-justify' style={{fontSize:'8px', maxWidth: '190mm', margin: 'auto', textAlign:'justify' }}>
        <span className='fw-muted fs-7'>
          <span className='fw-bold'>Observações:</span>
          <br />
          1) Os mestres da falange de Estrela Candente emitirão a procedência "DO ADJUNTO JANARÃ KOATAY 108, MESTRE CLÁUDIO", logo após o nome da falange;
          <br />
          2) Os mestres aponas emitem: -0-//;
          <br />
          3) Quando em qualquer trabalho o mestre ou ninfa estiverem a serviço de um Adjunto na posição de 1° Presidente ou Reino Central, emitirão "EM MISSÃO ESPECIAL DO ADJUNTO (...) KOATAY 108, MESTRE (...)" no final das suas emissões.
        </span>
      

      {/* RODAPÉ EMISSAO */}
      <div className='d-flex flex-column justify-content-between' style={{ maxWidth: '190mm', margin: 'auto', textAlign:'justify' }}>
        <div>
          <span className='fw-muted fw-bold fs-7'>
            <br />
            SALVE DEUS! MEUS FILHOS JAGUARES,
            O MESTRE QUE ALTERAR A SUA EMISSÃO TERÁ SOBRE SI A RESPONSABILIDADE DE NÃO ULTRAPASSAR O NEUTRÔN E CONSEQUENTEMENTE NÃO SERÁ OUVIDA NEM REGISTRADA PELOS PLANOS ESPIRITUAIS.
          </span>
        </div>
        </div>
        {/* Assinatura de Tia Neiva */}
        <div style={{ textAlign: 'right', marginTop: '1px' }}>
          <span className='fw-muted fs-7 fw-bold'>
            COM CARINHO, A MÃE EM CRISTO JESUS
          </span>
          <hr style={{ margin: '5px 0', border: '1px solid rgba(0, 0, 0, 0)', width: '80%' }} />
          <img className='h-40px' src={toAbsoluteUrl('/media/signatures/sign_tia_neiva.png')} alt='Assinatura' />
        </div>

        {/* Assinatura do Mestre Devas */}
        <div style={{ marginTop: '20px' }}>
          <div>
          <hr style={{ border: '1px solid rgba(0, 0, 0, 0.2)', width: '40%' }} /> 
            <span className='fw-muted fs-5'>
              Assinatura e Carimbo / Devas
            </span>
          </div>
        </div>
      </div>
      <div style={{textAlign: 'center', marginBottom: '1px' }}>
        <div> 
          <span className='fw-muted fs-8'>
            Impresso por { ( devas ? 
                            devas?.id_medium + ' - ' + 
                              ( (devas?.nome_medium || '').split(' ').length > 1 ? 
                              devas?.nome_medium?.split(' ')[0] + '.' +
                              devas?.nome_medium?.split(' ')[devas?.nome_medium?.split(' ').length - 1]
                              : devas.nome_medium)

                            : keycloak?.tokenParsed?.preferred_username ? 
                            (keycloak.tokenParsed.preferred_username) 
                            : (keycloak?.tokenParsed?.given_name) 
                            ) + ' em ' + moment(new Date()).format(SPACED_DATE_FORMAT) }
          </span>
        </div>
      </div>
    </div>
  );
};

export { EmissaoWidget };
