/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {bucketUrl, isNotEmpty, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/UsersListViewProvider'
import {Medium} from '../../core/_models'

type Props = {
  medium: Medium
}

const MediumInfoCell: FC<Props> = ({medium}) => {
  const {setItemIdForUpdate} = useListView();

  const openEditModal = () => {
    //console.log('id_medium:', id_medium);
    setItemIdForUpdate(medium.id_medium)
  }

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          { isNotEmpty(medium.foto) ? (
            <div className='symbol-label'>
              <img src={bucketUrl(`${medium.foto}`)} alt={medium.nome_medium?.charAt(0)} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                //`bg-light-${medium.initials?.state}`,
                `bg-light-primary`,
                //`text-${medium.initials?.state}`
                `text-primary}`
              )}
            >
              {
                (medium.nome_medium + '').split(' ').length > 1 ? 
                medium.nome_medium?.split(' ')[0].charAt(0) + '' + 
                medium.nome_medium?.split(' ')[1].charAt(0) 
                : medium.nome_medium?.charAt(0)
              }
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1' onClick={openEditModal}>
          {medium.nome_medium}
        </a>

        {medium.nome_emite ? 
        <span>Nome emissão: {medium.nome_emite}</span>
        : <></>
        }
        
      </div>
    </div>
  )
}

export {MediumInfoCell}
