import {FC, useMemo} from 'react'
import {ID} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/UsersListViewProvider'

type Props = {
  id: ID
}

const IdCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView();

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  return (
    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1' onClick={openEditModal}>
          {id}
        </a>
    </div>
  )
}

export {IdCell}
