/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon, QUERIES, bucketUrl, newDateUserTimezoneOffset, toAbsoluteUrl} from '../../../helpers'
import { useQuery } from 'react-query'
import { getEscalaByTemplo, removerEscala } from '../../../../app/modules/app/management/users-list/core/_requests'
import { EscalaDevas } from '../../../../app/modules/app/management/users-list/core/_models'
import moment from 'moment'

type Props = {
  className: string
}

const SPACED_DATE_FORMAT = "DD/MM/YYYY";

const EscalaDevasWidget: React.FC<Props> = ({className}) => {

  const [optionsDevas, setOptionsDevas] = useState<EscalaDevas[]>([]);

  let id_templo_mae = 1;
  let sufixTemple = ' DO AMANHECER';

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.DEVAS_ESCALADOS}`,
    () => {
      getEscalaByTemplo(id_templo_mae).then((res) => {
        setOptionsDevas(res.data || []);
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  );


  const removerDevas = (id_escala: number) => {
    removerEscala(id_escala).then((res) => {
      refetch();
    })
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Devas Escalados</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Clique para alterar a escala'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_escalar_devas'
          >
            <KTIcon iconName='calendar-edit' className='fs-3' />
            Adicionar
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-120px'>Templo</th>
                <th className='min-w-120px'>Data</th>
                <th className='min-w-120px'>Data final</th>
                {/*<th className='min-w-120px'>Tempo de sessão</th>*/}
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {optionsDevas.map(( escala, index ) => {
                return (
                  <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={bucketUrl(`${escala.devas_escalado?.foto}`)} alt={escala.devas_escalado?.nome_medium?.charAt(0)} />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {escala.devas_escalado?.nome_medium}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {escala.devas_escalado?.id_medium}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {escala.templo?.id_templo === 1 ?
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {escala.templo?.tx_cidade} - {escala.templo?.tx_sigla}
                      </a>
                      :
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {escala.templo?.tx_cidade} - {escala.templo?.tx_sigla} | {escala.templo?.tx_ministro_presidente + sufixTemple}
                      </a>
                    }
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>{moment(newDateUserTimezoneOffset(escala.init_date + '')).format(SPACED_DATE_FORMAT)}</span>
                      </div>
                    </div>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>{moment(newDateUserTimezoneOffset(escala.end_date + '')).format(SPACED_DATE_FORMAT)}</span>
                      </div>
                    </div>
                  </td>
                  {/*<td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>05:20m</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: '50%'}}
                        ></div>
                      </div>
                    </div>
                  </td>
                  */}
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        onClick={() => removerDevas(escala.id_escala || 0)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
                );
              })}

              { optionsDevas.length < 1 ?
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6'>
                          Sem dados a exibir
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                : <></>
              }
              
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {EscalaDevasWidget}
