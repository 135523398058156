/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import {KTIcon, isNotEmpty, newDateUserTimezoneOffset} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { Medium } from '../../../../app/modules/app/management/users-list/core/_models'

const SPACED_DATE_FORMAT = "DD/MM/YYYY";

type Props = {
  className: string,
  medium: Medium
}

const ClassificacaoTimeline: React.FC<Props> = ({medium, className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Histórico</span>
          {  medium?.dt_ultima_classificacao && medium?.sexo_medium === 'M' ?  
            <span className='text-muted fw-semibold fs-7'>Última classificação: {moment(newDateUserTimezoneOffset(medium.dt_ultima_classificacao + '')).format(SPACED_DATE_FORMAT)} - {medium.classificacaoDTO?.nome_classificacao}</span>
          : <></>
          }
        </h3>
        {/* begin::Menu */}
        {/*
        <div className='card-toolbar'>
          
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          
        </div>
        */}
        {/* end::Menu */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>

          {/* begin::Item */}
          {  medium?.dt_ultima_classificacao && medium?.sexo_medium === 'M'  ?
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{moment(newDateUserTimezoneOffset(medium.dt_ultima_classificacao + '')).format(SPACED_DATE_FORMAT)}</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-danger fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Content */}
              <div className='timeline-content d-flex'>
                <span className='timeline-content fw-semibold text-gray-800 ps-3'>Reclassificação: {medium.classificacaoDTO?.nome_classificacao}</span>
              </div>
              {/* end::Content */}
            </div>
            : <></>
          }
          {/* end::Item */}

          {/* begin::Item */}
          { isNotEmpty(medium.dt_setimo) ?
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{moment(newDateUserTimezoneOffset(medium.dt_setimo + '')).format(SPACED_DATE_FORMAT)}</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Text */}
              <div className='timeline-content fw-semibold text-gray-800 ps-3'>
                Sétimo Raio
              </div>
              {/* end::Text */}
            </div>
            : <></>
          }
          {/* end::Item */}

          {/* begin::Item */}
          {  isNotEmpty(medium?.dt_cav_guia) || isNotEmpty(medium?.cavaleiro_guia?.nome_cavaleiro_guia) ?
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{ moment(newDateUserTimezoneOffset(isNotEmpty(medium.dt_cav_guia) ? medium.dt_cav_guia + '' : (isNotEmpty(medium.dt_centuria) ? medium.dt_centuria + '' : 'Não informada') )).format(SPACED_DATE_FORMAT)}</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-primary fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Content */}
              <div className='timeline-content d-flex'>
                <span className='timeline-content fw-semibold text-gray-800 ps-3'>
                  Recebimento { (medium?.sexo_medium === 'M' && isNotEmpty(medium?.ministro?.nome_ministro)  ? 'Ministro ' + medium?.ministro?.nome_ministro + ' e ' : '' )  } 
                { (medium?.sexo_medium === 'M' ? 'Cavaleiro ' : 'Guia Missionária ') + medium?.cavaleiro_guia?.nome_cavaleiro_guia } </span>
              </div>
              {/* end::Content */}
            </div>
            : <></>
          }
          {/* end::Item */}

          {/* begin::Item */}
          { isNotEmpty(medium.dt_centuria) ?
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{moment(newDateUserTimezoneOffset(medium.dt_centuria + '')).format(SPACED_DATE_FORMAT)}</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-danger fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Text */}
              <div className='timeline-content fw-semibold text-gray-800 ps-3'>
                Centúria
              </div>
              {/* end::Text */}
            </div>
            : <></>
          }
          {/* end::Item */}


          {/* begin::Item */}
          { isNotEmpty(medium.dt_elevacao) ?
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{ moment(newDateUserTimezoneOffset(medium.dt_elevacao + '')).format(SPACED_DATE_FORMAT) }</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-primary fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Desc */}
              <div className='timeline-content fw-semibold text-gray-800 ps-3'>
                Elevação de Espadas
              </div>
              {/* end::Desc */}
            </div>
            : <></>
          }
          {/* end::Item */}


          {/* begin::Item */}
          { isNotEmpty(medium.dt_iniciacao) ?
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{ moment(newDateUserTimezoneOffset(medium.dt_iniciacao + '')).format(SPACED_DATE_FORMAT) }</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Text */}
              <div className='timeline-content fw-semibold text-gray-800 ps-3'>
                Iniciação
              </div>
              {/* end::Text */}
            </div>
            : <></>
          }
          {/* end::Item */}
          
          
          {/* begin::Item */}
          { isNotEmpty(medium.dt_emplac) ?
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{ moment(newDateUserTimezoneOffset(medium.dt_emplac + '')).format(SPACED_DATE_FORMAT) }</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-danger fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Text */}
              <div className='timeline-content fw-semibold text-gray-800 ps-3'>
                Identificação de Mentores
              </div>
              {/* end::Text */}
            </div>
            : <></>
          }
          {/* end::Item */}

          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>{ isNotEmpty(medium.dt_ingresso) ? moment(newDateUserTimezoneOffset(medium.dt_ingresso + '')).format(SPACED_DATE_FORMAT) : 'Data não informada' }</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='timeline-content fw-semibold text-gray-800 ps-3'>
              Ingresso
            </div>
            {/* end::Text */}
          </div>
          {/* end::Item */}


        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ClassificacaoTimeline}
