const QUERIES = {
  OVERVIEW_MEDIUM: 'overview-medium',
  MEDIUMS_LIST: 'medium-list',
  TEMPLES_LIST: 'temple-list',
  PRESIDENTES_LIST: 'presidente-list',
  COMPONENTES_LIST: 'componentes-list',
  COMPONENTES_TB_LIST: 'componentes-tb-list',
  DEVAS_LIST: 'devas-list',
  DEVAS_ESCALADOS: 'devas-escalados',
  ELEVACAO_LIST: 'elevacao-list',
  FALANGE_MISSIONARIA_LIST: 'falange-missionaria-list',
  CENTURIA_LIST: 'centuria-list',
  ADJUNTO_APOIO_LIST: 'adjunto-apoio-list',
  FALANGE_MESTRADO_LIST: 'falange-mestrado-list',
  CLASSE_LIST: 'classe-list',
  CLASSIFICACAO_LIST: 'classificacao-list',
  VINCULOS_LIST: 'vinculos-list',
  CURSO_LIST: 'curso-list',
  AUDIT_LIST_HOME: 'audit-list-home',
  AUDIT_LIST: 'audit-list'
}

export {QUERIES}
