/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTIcon, QUERIES, isNotEmpty, newDateUserTimezoneOffset, toAbsoluteUrl} from '../../../helpers'
import { Medium, Templo } from '../../../../app/modules/app/management/users-list/core/_models'
import moment from 'moment';
import { useQuery } from 'react-query';
import { getAllMediumsByTemplo } from '../../../../app/modules/app/management/users-list/core/_requests';


const SPACED_DATE_FORMAT = "DD/MM/YYYY";

type Props = {
  className: string
  templo: Templo
}

const ComponentesTable: React.FC<Props> = ({className, templo}) => {
  let sufixTemple = ' DO AMANHECER';

  const [optionsMediums, setOptionsMediums] = useState([{id_medium: 0, nome_medium: '', mediunidade: ''}]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.COMPONENTES_TB_LIST}`,
    () => {
      const arr: any[] | ((prevState: string[]) => string[]) = [];
      getAllMediumsByTemplo(templo.id_templo).then((res) => {
        res.data?.filter((medium) => (templo.id_medium_presidente != medium.id_medium) ).map((medium) => {
          arr.push({ id_medium: medium.id_medium, 
            nome_medium: medium.nome_medium?.toLocaleUpperCase(), 
            mediunidade: medium.mediunidadeDTO?.nome_mediunidade?.toLocaleUpperCase()  
          });
        });
        setOptionsMediums(arr);
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  );

  return (
    <>
    <div className={`card card-flush ${className}`} style={{ maxWidth: '210mm', margin: '30px auto', border: '1px solid rgba(0, 0, 0, 0.2)', padding: '15px' }}>
      <div className='card-header justify-content-center cursor-pointer' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', marginBottom: '20px' }}>
        <div className='d-flex align-items-center justify-content-between' style={{ maxWidth: '190mm', margin: 'auto' }}>
          <div>
            <img className='h-60px' style={{ marginRight: '15px' }} src={toAbsoluteUrl('/media/logos/devas.png')} alt='Devas' />
          </div>
          <div className='mr-4' style={{ maxWidth: '90%' }}>
            <h3 className='fw-bolder m-0' style={{ marginLeft: 'auto', textAlign: 'center' }}>
              OSOEC - OBRAS SOCIAIS DA ORDEM ESPIRITUALISTA CRISTÃ
            </h3>
            <h3 className='m-0' style={{ marginLeft: 'auto', textAlign: 'center' }}>
            {` Templo 
                ${templo?.tx_cidade} - ${templo?.tx_sigla} | ${templo?.tx_ministro_presidente + sufixTemple}
              `}
            </h3>
            
          </div>
          <div>
            <img className='h-60px' style={{ marginLeft: '15px' }} src={toAbsoluteUrl('/media/logos/osoec-logo-rounded.png')} alt='Logo' />
          </div>
        </div>
      </div>
      <table className="table table-hover table-rounded table-striped border gy-1 gs-9">
        <thead>
            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                <th>Nº da Ficha</th>
                <th>Nome do Médium</th>
                <th>Mediunidade</th>
            </tr>
        </thead>
        <tbody>
          { optionsMediums.map(( medium, index ) => {
              return (
                <tr key={index}>
                  <td>{medium.id_medium}</td>
                  <td>{medium.nome_medium}</td>
                  <td>{medium.mediunidade}</td>
                </tr>
              );
            })
          }
          <tr>
            <td></td>
            <td></td>
            <td><hr style={{ border: '1px solid rgba(0, 0, 0, 0.2)', width: '50%'}} /> </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Total {optionsMediums.length} Componentes</td>
          </tr>
        </tbody>
      </table>
    </div>
    </>
  )
}

export {ComponentesTable}


