/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {defaultAlerts,  defaultLogs, KTIcon, toAbsoluteUrl} from '../../../helpers'

const MenuMadalena: FC = () => (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-no-repeat rounded-top'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
        Informativos <span className='fs-6 opacity-75 ps-3'>maya</span>
      </h3>

      <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
        
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_madalena1'
          >
            Reunião
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_madalena2'
          >
            Escalada
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_madalena3'
          >
            Imantração
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_madalena4'
          >
            Canto
          </a>
        </li>

        
      </ul>
       </div>

        <div className='tab-content'>     

          <div className='tab-pane fade' id='kt_topbar_madalena1' role='tabpanel1'>
            <div className='d-flex align-items-center mb-8'>
              {/* begin::Avatar */}
            <div className='symbol symbol-40px me-8'>
            <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='' alt='' />
          </div>
            {/* end::Avatar */}
            {/* begin::Text */}
            <div className='flex-grow-1'>
             <a href='#' 
             className='text-dark fw-bold text-hover-primary fs-6'
             data-bs-toggle='modal'
             data-bs-target='#kt_modal_info_madalena1'
               >
              madalena
             </a>
              <span className='text-muted d-block fw-semibold'>
              Project Manager        
            </span>
          </div>
          {/* end::Text */}
         </div>     
         <div className='text-center px-4'>
            <img
              className='mw-100 mh-200px'
              alt=''
              src={toAbsoluteUrl('/media/illustrations/dozzy-1/1.png')}
            />
           </div>           
       </div>

       <div className='tab-pane fade' id='kt_topbar_madalena2' role='tabpanel1'>
            <div className='d-flex align-items-center mb-8'>
              {/* begin::Avatar */}
            <div className='symbol symbol-40px me-8'>
            <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='' alt='' />
          </div>
            {/* end::Avatar */}
            {/* begin::Text */}
            <div className='flex-grow-1'>
             <a href='#' 
             className='text-dark fw-bold text-hover-primary fs-6'
             data-bs-toggle='modal'
             data-bs-target='#kt_modal_info_madalena2'
               >
              madalena
             </a>
              <span className='text-muted d-block fw-semibold'>
              Project ManagerProject ManagerProject ManagerProject ManagerProject ManagerProject Manager            
            </span>
          </div>
          <div className='text-center px-4'>
            <img
              className='mw-100 mh-200px'
              alt=''
              src={toAbsoluteUrl('/media/illustrations/dozzy-1/1.png')}
            />
           </div>   
          {/* end::Text */}
         </div>             
       </div>

       <div className='tab-pane fade' id='kt_topbar_madalena3' role='tabpanel1'>
            <div className='d-flex align-items-center mb-8'>
              {/* begin::Avatar */}
            <div className='symbol symbol-40px me-8'>
            <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='' alt='' />
          </div>
            {/* end::Avatar */}
            {/* begin::Text */}
            <div className='flex-grow-1'>
             <a href='#' 
             className='text-dark fw-bold text-hover-primary fs-6'
             data-bs-toggle='modal'
             data-bs-target='#kt_modal_info_madalena3'
               >
              madalena
             </a>
              <span className='text-muted d-block fw-semibold'>
              Project ManagerProject ManagerProject ManagerProject ManagerProject ManagerProject Manager            
            </span>
          </div>
          {/* end::Text */}
         </div>             
       </div>
      


       <div className='tab-pane fade show active' id='kt_topbar_madalena4' role='tabpanel'>
        <div className='d-flex flex-column px-9'>
           <div className='text-center mt-5 mb-9'>
              <a
                href='#'
                className='btn btn-sm btn-primary px-6'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_info_madalena4'
              >
                madalena
              </a>
            </div>
          </div>
           <div className='text-center px-4'>
            <img
              className='mw-100 mh-200px'
              alt=''
              src={toAbsoluteUrl('/media/illustrations/dozzy-1/1.png')}
            />
           </div>      
        </div>        
      </div>      
  </div>
)

export {MenuMadalena}