/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTIcon, QUERIES, newDateUserTimezoneOffset, toAbsoluteUrl} from '../../../helpers'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getAllAudits, getAudit } from '../../../../app/modules/app/management/users-list/core/_requests'
import moment from 'moment'
import { MediumAudit } from '../../../../app/modules/app/management/users-list/core/_models'

const SPACED_DATE_FORMAT = "DD/MM/YYYY";


type Props = {
  className: string
}

const AlteracoesFichasWidget: React.FC<Props> = ({className}) => {

  const navigate = useNavigate();
  const [totalFichas, setTotalFichas] = useState(0);
  const [auditState, setAuditState] = useState<MediumAudit[]>([]);
  let sufixTemple = ' DO AMANHECER';

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.AUDIT_LIST_HOME}`,
    () => {
      return getAllAudits().then((res) => {
        //console.log(res.data)
        const data = res?.data?.filter((audit) => !audit.id_medium_approver);
        setAuditState(data || []);
        setTotalFichas(data?.length || 0)
      });
    },
    {
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const handleNavigate = () => {
    let path = `/app/enroll/medium/00000/novo`; 
    navigate(path);
  }

  const detalhe = (id_medium: number) => {
    let path = `/app/enroll/medium/${id_medium}/detalhe`; 
    refetch();
    navigate(path);
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Alterações de Fichas</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{totalFichas} ficha(s) aguardando aprovação</span>
        </h3>
        <div className='card-toolbar'>
          <a href='#' onClick={handleNavigate} className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            Nova Ficha
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          { auditState.length > 0 ?
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-325px rounded-start'>Nome do Médium</th>
                  <th className='min-w-125px'>Ficha</th>
                  <th className='min-w-125px'>Templo</th>
                  <th className='min-w-125px'>Solicitante</th>
                  <th className='min-w-125px'>Data de Alteração</th>
                  <th className='min-w-150px'>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
              { auditState.map(( audit, index ) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-muted fw-bold text-hover-primary mb-1 fs-6' onClick={() => detalhe(audit.id_medium || 0)}>
                          {audit.audited?.nome_medium}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-muted fw-bold text-hover-primary d-block fs-7' onClick={() => detalhe(audit.id_medium || 0)}>
                        {audit.id_medium}
                      </a>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        { audit.templo?.tx_cidade + ' - ' + audit.templo?.tx_sigla + (audit.templo?.id_templo !== 1 ?  ' | ' + audit.templo?.tx_ministro_presidente + sufixTemple : '') }
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {audit.requester?.nome_medium}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        { audit.dt_request ? moment(newDateUserTimezoneOffset(audit.dt_request)).format(SPACED_DATE_FORMAT) : '' }
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'> {audit.id_medium_approver ? 'APROVADO' : 'PENDENTE'}</span>
                    </td>
                  </tr>
                );
              })}

              </tbody>
              {/* end::Table body */}
            </table>
            : <><label className='form-check-label fw-bold ps-2 fs-6' htmlFor='deactivate'>Sem pendências para aprovação</label></>
          }
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {AlteracoesFichasWidget}
