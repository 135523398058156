/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {KTIcon, QUERIES, bucketUrl, isEmpty, isNotEmpty, newDateUserTimezoneOffset} from '../../../../_metronic/helpers'
import {ChartsWidget1, ClassificacaoTimeline, TablesWidget1, TablesWidget5,} from '../../../../_metronic/partials/widgets'
import { Medium, Templo } from '../../app/management/users-list/core/_models'
import moment from 'moment'
import ReactToPrint, { PrintContextConsumer, useReactToPrint } from 'react-to-print'
import { Button } from 'react-bootstrap'
import ComponentesPrint from './ComponentesPrint'
import { useQuery } from 'react-query'
import { getAllMediumsByTemplo } from '../../app/management/users-list/core/_requests'
import clsx from 'clsx'

const SPACED_DATE_FORMAT = "DD/MM/YYYY";

export function ComponentesList(templo: Templo) {

  const navigate = useNavigate();
  let sufixTemple = ' DO AMANHECER';

  const componentRef = useRef<ComponentesPrint>(null);
  const [optionsMediums, setOptionsMediums] = useState([{id_medium: 0, nome_medium: 'AA AA', nome_emite: '', foto: '', mediunidade: ''}]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.COMPONENTES_LIST}`,
    () => {
      const arr: any[] | ((prevState: string[]) => string[]) = [];
      getAllMediumsByTemplo(templo.id_templo).then((res) => {
        res.data?.filter((medium) => (templo.id_medium_presidente != medium.id_medium) ).map((medium) => {
          arr.push({ id_medium: medium.id_medium, 
            nome_medium: medium.nome_medium?.toLocaleUpperCase(), 
            nome_emite: medium.nome_emite,
            foto: medium.foto,
            mediunidade: medium.mediunidadeDTO?.nome_mediunidade?.toLocaleUpperCase()  
          });
        });
        setOptionsMediums(arr);
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  );

  const detalhe = (id_medium: number) => {
    let path = `/app/enroll/medium/${id_medium}/detalhe`; 
    refetch();
    navigate(path);
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{optionsMediums.length} Componentes</h3>
          </div>

          <div className='card-toolbar'>

            <ReactToPrint
              trigger={() => 
                <Link to={'#'} className='btn btn-primary align-self-center card-space'>
                  Imprimir
                </Link>
              }
              documentTitle = {`Lista Templo 
                ${templo?.tx_cidade} - ${templo?.tx_sigla} - ${templo?.tx_ministro_presidente + sufixTemple}
              `}
              content={() => componentRef.current}
            />

            <div style={{ display: 'none' }}>
              <ComponentesPrint templo={templo} ref={componentRef} />
            </div>

            <Link to={'/app/management/templo/search'} className='btn btn-primary align-self-center'>
              Voltar
            </Link>

          </div>

        </div>

        <div className='card-body p-9'>

          <table className="table table-hover table-rounded table-striped align-middle border gy-7 gs-7">
            <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                    <th>Nº da Ficha</th>
                    <th>Nome do Médium</th>
                    <th>Mediunidade</th>
                </tr>
            </thead>
            <tbody>
              { optionsMediums.map(( medium, index ) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex flex-column'>
                          <a href='#' className='text-gray-800 text-hover-primary mb-1' onClick={() => detalhe(medium.id_medium)}>
                            {medium.id_medium}
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                            <a href='#' className='text-gray-800 text-hover-primary mb-1' onClick={() => detalhe(medium.id_medium)}>
                              <div className={clsx('symbol-label fs-3', `bg-light-primary`, `text-primary}`)}>
                                { medium.nome_medium?.split(' ').length > 1 ? 
                                  medium.nome_medium?.split(' ')[0].charAt(0) + 
                                  medium.nome_medium?.split(' ')[1].charAt(0) 
                                  : medium.nome_medium.charAt(0) }
                              </div>
                            </a>
                          </div>
                          <div className='d-flex flex-column'>
                            <a href='#' className='text-gray-800 text-hover-primary mb-1' onClick={() => detalhe(medium.id_medium)}>
                              {medium.nome_medium}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>{medium.mediunidade}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          
        </div>
      </div>

    </>
  )
}
