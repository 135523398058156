/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import {KTIcon, bucketUrl, isEmpty, isNotEmpty, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {Dropdown1} from '../../../_metronic/partials'
import {useLocation} from 'react-router'
import { Templo } from '../app/management/users-list/core/_models'
import { useQuery, useQueryClient } from 'react-query'
import { getSimpleMediumById, updateFoto } from '../app/management/users-list/core/_requests'
import { toast } from 'react-toastify'

const TemploHeader: React.FC<Templo> = (templo :Templo) => {

  const location = useLocation();
  let sufixTemple = ' DO AMANHECER';
  const queryClient = useQueryClient();

  const {
    data: presidente,
  } = useQuery(
    `presidente-${templo.id_medium_presidente}`,
    () => {
      if (templo.id_medium_presidente === 0) {
        return null;
      }
      return getSimpleMediumById(templo.id_medium_presidente)
    },
    {
      onError: (err) => {
        console.error(err)
      },
    }
  );

  const changeFoto = (event: any) => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('image', selectedFile);
    updateFoto(presidente?.id_medium || 0, formData).then(() => {
      queryClient.invalidateQueries({ queryKey: [`presidente-${templo.id_medium_presidente}`] });
      toast.success('Foto atualizada com sucesso!');
      event.target.value = '';
    });
  }

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [`presidente-${templo.id_medium_presidente}`] });
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: `url('${toAbsoluteUrl('/media/svg/avatars/blank.svg')}')`}}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-300px h-200px'
                style={{backgroundImage: `url('${bucketUrl(`${presidente?.foto}`)}')`}}
              ></div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Alterar foto'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                  <input id="upload" name='avatar' type="file" accept='.png, .jpg, .jpeg'
                    onChange={(event) => {changeFoto(event)}}
                  />
                  
                <input type='hidden' name='avatar_remove' />
              </label> 
              {/* end::Label */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remover foto'
              >
                <i className='bi bi-x fs-2'></i>
              </span> 
              {/* end::Remove */}

            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-6 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-1'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {templo && templo.id_templo === 1  ? (
                        templo.tx_cidade
                      ) : (
                        templo?.tx_cidade + ' - ' + templo?.tx_sigla + ' | ' + templo?.tx_ministro_presidente + sufixTemple
                    )}
                  </a>
                  
                </div>
           
              </div>

            </div>

            <div className='d-flex flex-wrap flex-stack'>

              <div className='d-flex flex-column flex-grow-1 pe-8'>

                <div className='d-flex flex-wrap'>

                { isNotEmpty(presidente?.nome_medium) ?
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-10'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='user-square' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>PRESIDENTE</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{ presidente?.nome_medium }</div>
                    </div>
                    : <></>
                  }
                  
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-10'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='sun' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>ORIGEM</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>{ presidente?.adjunto_raiz?.nome_adjunto_raiz }</div>
                  </div>

                  { isNotEmpty(presidente?.ministro?.id_ministro) ?
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-10'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='vuesax' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>MINISTRO</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{ presidente?.ministro?.nome_ministro }</div>
                    </div>
                    : <></>
                  }
                  
                </div>
              </div>
            </div>

            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/app/enroll/templo/' + (templo.id_templo) + '/detalhe' && 'active')
                    }
                    to={'/app/enroll/templo/' + templo.id_templo + '/detalhe'}
                  >
                    Alteração
                  </Link>
                </li>
                { templo.id_templo !== 1 ?
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/app/enroll/templo/' + (templo.id_templo) + '/componentes' && 'active')
                      }
                      to={'/app/enroll/templo/' + templo.id_templo + '/componentes'}
                    >
                      Componentes
                    </Link>
                  </li>
                  : <></>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TemploHeader}
