/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTIcon, QUERIES, bucketUrl, isEmpty, isNotEmpty, newDateUserTimezoneOffset, toAbsoluteUrl, useDebounce} from '../../../helpers'
import { useQuery, useQueryClient } from 'react-query';
import { getAllDevasByTemplo, getSimpleMediumById, updateEscala } from '../../../../app/modules/app/management/users-list/core/_requests';
import SelectSearch from 'react-select-search';
import { EscalaDevas, Medium } from '../../../../app/modules/app/management/users-list/core/_models';
import { Link } from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR);

const EscalarDevas: FC = () => {

  const [optionsDevas, setOptionsDevas] = useState([{value: 0, name: 'Carregando...'}]);
  const [selectedDevas, setSelectedDevas] =  useState(0);
  const [escalaState, setEscalaState] =  useState<EscalaDevas[]>([]);
  const [isAdding, setIsAdding] =  useState(false);
  const [startDate, setStartDate] =  useState(new Date().toUTCString());
  const [endDays, setEndDays] =  useState(1);

  let id_templo_mae = 1;
  const queryClient = useQueryClient();

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.DEVAS_LIST}`,
    () => {
      const arr: any[] | ((prevState: string[]) => string[]) = [];
      getAllDevasByTemplo(id_templo_mae).then((res) => {
        res.data?.map((medium) => {
          arr.push({ 
            value: medium.id_medium, 
            name: `${medium.id_medium} - ${medium.nome_medium?.toLocaleUpperCase()}`, 
          });
        });
        setOptionsDevas(arr);
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  );

  const addDevas = () => {
   //console.log(selectedDevas);
   setIsAdding(true);
   if (selectedDevas == 0 ) {
    setIsAdding(false);
    return 0;
   }

   // evitar adicionar mais de uma vez
   if (escalaState.filter((escala) => escala.devas_escalado?.id_medium === selectedDevas).length > 0){
    setIsAdding(false);
    return 0;
   }

   getSimpleMediumById(selectedDevas).then((res) => {
    escalaState.push({
      devas_escalado: res || {},
      templo: res?.templo || {},
      in_ativo: 1,
    } || {});
    setSelectedDevas(0);
    setIsAdding(false);
   });
 
  }
  
  const removerDevas = (id_medium: number) => {
    setEscalaState(
      escalaState.filter(escala =>
        escala.devas_escalado?.id_medium !== id_medium
      )
    );
  }

  const cancelar = () => {
    setSelectedDevas(0);
    setEscalaState([]);
    setStartDate(new Date().toUTCString());
    setEndDays(1);
    setIsAdding(false);
  }

  const salvar = () => {

    const start_dt = isNotEmpty(startDate) ? new Date(startDate) : new Date();
    const end_dt = start_dt;
    end_dt.setDate(end_dt.getDate() + endDays);

    escalaState.map((escala)=> {
      escala.init_date = isNotEmpty(startDate) ? new Date(startDate).toISOString() : new Date().toISOString();
      escala.end_date = end_dt.toISOString();
    });

   updateEscala(escalaState).then((res)=> {
      queryClient.invalidateQueries({ queryKey: [QUERIES.DEVAS_ESCALADOS] });
      setSelectedDevas(0);
      setEscalaState([]);
      setStartDate(new Date().toUTCString());
      setEndDays(1);
      setIsAdding(false);
    });
  }

  return (
    <div className='modal fade' id='kt_modal_escalar_devas' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-16 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Adicionar Escala</h1>
              <div className='text-muted fw-bold fs-5'>
              </div>
            </div>
            <div className='d-flex flex-stack py-4'>
              <div className='col-lg-5 fv-row me-4'>
                <DatePicker
                  selected={ startDate ? newDateUserTimezoneOffset(startDate) : undefined }
                  onChange={(value) => setStartDate(value + '')}
                  minDate={newDateUserTimezoneOffset(new Date().toUTCString())}
                  className='form-control form-control-lg form-control-solid'
                  placeholderText='Data inicial'
                  todayButton="Hoje"
                  dateFormat="dd/MM/yyyy"
                  locale="ptBR"
                />
              </div>
              <div className='col-lg-2 fv-row'>
                <input
                  type='number'
                  min={1}
                  max={7}
                  value={endDays}
                  onChange={(e) => setEndDays(parseInt(e.target.value))}
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Dias'
                />
              </div>
              <div className='col-lg-3 fv-row'>
                <span className='fs-6 fw-bold mb-2'>Dias (máx. 7)</span>
              </div>
            </div>

            <div className='d-flex flex-stack py-4'>
              <div className='col-lg-9 fv-row me-8'>
                <SelectSearch
                  className='select-search' 
                  search 
                  placeholder='Digite o nome do Devas aqui' 
                  options={optionsDevas} 
                  value={selectedDevas.toString()}
                  onChange={selectedOption => setSelectedDevas(parseInt(selectedOption + ''))}
                />
              </div>
            
              <div className='col-lg-3 fv-row'>
                <button
                  type='button'
                  onClick={() => addDevas()}
                  className='btn btn-primary me-2'
                  data-kt-users-modal-action='submit'
                  disabled={isAdding}
                >
                  Add
                </button>
              </div>
            </div>
            <div className='separator d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'></span>
            </div>

            <div className='mb-10'>
              <div className='fs-6 fw-bold mb-2'>Devas adicionados</div>

              <div className='mh-300px scroll-y me-n7 pe-7'>
                {escalaState.map((escala, i) => {
                  return (
                    <div
                      className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                      key={i}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-35px symbol-circle'>
                          {escala.devas_escalado?.foto && <img alt='Foto' src={bucketUrl(escala.devas_escalado.foto)} />}
                          {!escala.devas_escalado?.foto && (
                            <div className='symbol symbol-35px symbol-circle'>
                              <span
                                className={`symbol-label bg-light-danger text-danger fw-bold`}
                              >
                              { (escala.devas_escalado?.nome_medium || '').split(' ').length > 1 ? 
                                  (escala.devas_escalado?.nome_medium || '').split(' ')[0].charAt(0) + 
                                  (escala.devas_escalado?.nome_medium || '').split(' ')[1].charAt(0) 
                                  : (escala.devas_escalado?.nome_medium || '').charAt(0) }
                              </span>
                            </div>
                          )}
                                
                        </div>

                        <div className='ms-5'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                          >
                            {escala.devas_escalado?.nome_medium}
                          </a>
                          <div className='fw-bold text-muted'>{escala.devas_escalado?.email}</div>
                        </div>
                      </div>

                      <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Clique para remover'
                      >
                        <a
                          href='#'
                          className='btn btn-sm btn-light-primary'
                          onClick={() => removerDevas(escala.devas_escalado?.id_medium || 0)}
                        >
                          Remover
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='d-flex flex-end'>
                <button
                  type='button'
                  onClick={() => cancelar()}
                  className='btn btn-primary me-3'
                  data-bs-dismiss='modal'
                  data-kt-users-modal-action='submit'
                  //disabled={formik.isSubmitting || isMediumLoading}
                >
                  Cancelar
                </button>
                <button
                  type='button'
                  onClick={() => salvar()}
                  className='btn btn-primary me-3'
                  data-bs-dismiss='modal'
                  data-kt-users-modal-action='submit'
                  //disabled={formik.isSubmitting || isMediumLoading}
                >
                  Salvar
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EscalarDevas}
