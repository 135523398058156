import React, { useEffect, useState } from 'react'
import {Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useQuery } from "react-query"
import {FichaOverview} from './components/FichaOverview'
import {FichaForm} from './components/settings/FichaForm'
import { getTempleById } from '../app/management/users-list/core/_requests'
import { Templo } from '../app/management/users-list/core/_models'
import { TemploHeader } from './TemploHeader'
import { TemploForm } from './components/settings/TemploForm'
import { ComponentesList } from './components/ComponentesList'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Templo',
    path: '/app/enroll/templo/:number/detalhe',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TemploPage: React.FC = () => {
  let { number } = useParams();

  const [temploState, setTemploState] = useState<Templo>();
  
  const {
    isLoading,
    data: templo,
    error,
  } = useQuery(
    `templo-${number}`,
    () => {
      getTempleById(Number(number)).then((res) => {
        setTemploState(res);
      });
    },
    {
      onError: (err) => {
        console.error(err)
      },
    },
  )

  useEffect(() => {
    //getMediumById(Number(number)).then((res) => {
      //setMediumState(res);
    //});
  }, [temploState]);

  return (
    <Routes>
      <Route
        path='novo'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Cadastro</PageTitle>
            <TemploForm />
            
          </>
        }
      />
      <Route
        element={
          <>
            <TemploHeader {...temploState} />
            <Outlet />
          </>
        }
      >
        <Route
          path='detalhe'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Alteração</PageTitle>
              <TemploForm {...temploState} /> 
            </>
          }
        />
        <Route
          path='componentes'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Componentes</PageTitle>
              <ComponentesList {...temploState} /> 
            </>
          }
        />
         
        <Route index element={<Navigate to='detalhe' />} />
      </Route>
    </Routes>
  )
}

export default TemploPage
