import {FC} from 'react'
import { Templo } from '../../core/_models'

type Props = {
  templo?: Templo
}

const PresidenteTemploCell: FC<Props> = ({templo}) => (
  <> 
   
      <div className='badge badge-light-success fw-bolder'>  
      
        { templo?.tx_nome_presidente }

      </div>
    
  </>
)

export {PresidenteTemploCell}
