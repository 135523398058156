import {useNavigate } from 'react-router-dom'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/TemplosListViewProvider'
import {TemplosListFilter} from './TemplosListFilter'

type Props = {
  add_path: string
}

const TemplosListToolbar: React.FC<Props> = ({add_path}) => {

  const navigate = useNavigate()

  const {setItemIdForUpdate} = useListView()
  
  const redirectAdd = () => {
    setItemIdForUpdate(null)
    navigate(add_path);
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <TemplosListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Exportar
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={redirectAdd}>
        <KTIcon iconName='plus' className='fs-2' />
        Novo Cadastro
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {TemplosListToolbar}
