// @ts-nocheck
import {Column} from 'react-table'
import {MediumInfoCell} from './MediumInfoCell'
import {MediumMediumshipCell} from './MediumMediumshipCell'
import {TemploCell} from './TemploCell'
import {ActionsCell} from './ActionsCell'
import {MediumSelectionCell} from './MediumSelectionCell'
import {MediumDateFieldCell} from './MediumDateFieldCell'
import {MediumCustomHeader} from './MediumCustomHeader'
import {MediumSelectionHeader} from './MediumSelectionHeader'
import {Medium, Templo} from '../../core/_models'
import { IdCell } from './IdCell'
import { TemploCustomHeader } from './TemploCustomHeader'
import { PresidenteTemploCell } from './PresidenteTemploCell'
import { TemplosActionsCell } from './TemplosActionsCell'
import { IdTemploCell } from './IdTemploCell'

const usersColumns: ReadonlyArray<Column<Medium>> = [
  {
    Header: (props) => <MediumSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <MediumSelectionCell id={props.data[props.row.index].id_medium} />,
  },
  {
    Header: (props) => <MediumCustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'nome_medium',
    Cell: ({...props}) => <MediumInfoCell medium={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <MediumCustomHeader tableProps={props} title='Ficha' className='min-w-125px' />,
    id: 'id_medium',
    Cell: ({...props}) => <IdCell id={props.data[props.row.index].id_medium} />,
  },
  {
    Header: (props) => (
      <MediumCustomHeader tableProps={props} title='Mediunidade' className='min-w-125px' />
    ),
    id: 'mediunidade',
    Cell: ({...props}) => <MediumMediumshipCell mediunidade={props.data[props.row.index].mediunidadeDTO.nome_mediunidade} />,
  },
  {
    Header: (props) => (
      <MediumCustomHeader tableProps={props} title='Templo' className='min-w-125px' />
    ),
    id: 'templo',
    Cell: ({...props}) => <TemploCell templo={props.data[props.row.index].templo} />,
  },
  {
    Header: (props) => (
      <MediumCustomHeader tableProps={props} title='Data de nascimento' className='min-w-125px' />
    ),
    id: 'dt_nascimento',
    Cell: ({...props}) => <MediumDateFieldCell date={props.data[props.row.index].dt_nascimento} />
  },
  {
    Header: (props) => (
      <MediumCustomHeader tableProps={props} title='Data de ingresso' className='min-w-125px' />
    ),
    id: 'dt_ingresso',
    Cell: ({...props}) => <MediumDateFieldCell date={props.data[props.row.index].dt_ingresso} />
  },
  {
    Header: (props) => (
      <MediumCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id_medium} />,
  },
]

const templosColumns: ReadonlyArray<Column<Templo>> = [
  

  {
    Header: (props) => (
      <TemploCustomHeader tableProps={props} title='Templo' className='min-w-125px' />
    ),
    id: 'id_templo',
    Cell: ({...props}) => <IdTemploCell id={props.data[props.row.index].id_templo} />,
  },
  {
    Header: (props) => (
      <TemploCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    id: 'nome_templo',
    Cell: ({...props}) => <TemploCell templo={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => <TemploCustomHeader tableProps={props} title='Presidente' className='min-w-125px' />,
    id: 'presidente',
    Cell: ({...props}) => <PresidenteTemploCell templo={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => (
      <TemploCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <TemplosActionsCell id={props.data[props.row.index].id_templo} />,
  },
]

export {usersColumns, templosColumns}
