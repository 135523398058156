import React from "react";
import { Templo } from "../../app/management/users-list/core/_models";
import { ComponentesTable } from "../../../../_metronic/partials/widgets/tables/ComponentesTable";

type Props = {
  templo: Templo;
};

export default class ComponentesPrint extends React.Component<Props>{

 render() {
   return (
      <>
        <ComponentesTable templo={this.props.templo} className='mb-5 mb-xl-8' />
      </>
   );
 }
}
