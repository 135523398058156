import {TemplosListViewProvider, useListView} from './core/TemplosListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseTemplosProvider, useQueryResponse} from './core/QueryResponseTemplosProvider'
import {TemplosListHeader} from './components/header/TemplosListHeader'
import {UserEditModal} from './user-edit-modal/UserEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import { TemplosTable } from './table/TemplosTable'
import { useNavigate } from 'react-router-dom'
import { EscalaDevasWidget } from '../../../../../_metronic/partials/widgets'

const DevasList = () => {

  return (
    <>
      <KTCard>
        <EscalaDevasWidget className='mb-5 mb-xl-8' />
      </KTCard>
    </>
  )
}

const DevasListWrapper = () => (
  <DevasList />
)

export {DevasListWrapper}
