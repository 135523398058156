import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR';
import {QUERIES, isEmpty, isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialTemplo as initialValuesTemplo,  Medium, Templo } from '../../../../app/management/users-list/core/_models'

import { createTemple } from '../../../../app/management/users-list/core/_requests'
import { useQueryClient } from 'react-query'
import SelectSearch from 'react-select-search';
import '../select-search-style.css'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


registerLocale('ptBR', ptBR);

Yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
    notOneOf: 'Informar Masculino ou Feminino é obrigatório',
  },
});

const temploDetailsSchema = Yup.object().shape({
   

})


const DadosGeraisTemploFormNew = ({}) => {

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formik = useFormik<Templo>({
    initialValues: initialValuesTemplo,
    validationSchema: temploDetailsSchema,

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      setLoading(true);
      let path = '';
      try {
        await createTemple(values).then((temploCreated) => {
          path = `/app/enroll/templo/${temploCreated?.id_templo}/detalhe`; 
          toast.success('Cadastro feito com sucesso!')
         
        })
      } catch (ex) {
        if (isNotEmpty(values.id_templo)) {
          toast.error('Erro ao alterar Templo')
        } else {
          toast.error('Erro ao cadastrar Templo')
        }
        console.error(ex)
      } finally {
        queryClient.invalidateQueries({ queryKey: [`medium-${values.id_templo}`] });
        setTimeout(() => {
          setLoading(false);
          navigate(path);
        }, 3000)
      }
      
    },
  })


  const isTouched = () => {
    return formik.dirty
  }

  const reset = () => {
    formik.resetForm()
  }

  const voltar = () => {
    navigate(-1)
  }

  return (
    <>
   
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_dados_gerais_form'
          aria-expanded='true'
          aria-controls='kt_dados_gerais_form'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Novo Cadastro</h3>
          </div>
        </div>

        <div id='kt_dados_gerais_form' className='collapse show'>
          <form onSubmit={formik.handleSubmit}  className='form'>
            <div className='card-body border-top p-9 col-lg-12'>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Endereço</label>

                <div className='col-lg-9'>
                  <div className='row'>

                    <div className='col-lg-6 fv-row'>
                      <textarea
                        maxLength={250}
                        rows={2}
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Informar endereço completo'
                        {...formik.getFieldProps('tx_endereco')}
                      />
                      {formik.touched.tx_endereco && formik.errors.tx_endereco && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tx_endereco}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className='required'>Cidade / CEP</span>
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        maxLength={50}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Cidade'
                        {...formik.getFieldProps('tx_cidade')}
                      />
                      {formik.touched.tx_cidade && formik.errors.tx_cidade && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tx_cidade}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-3 fv-row'>
                      <input
                        type='text'
                        maxLength={10}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='CEP'
                        {...formik.getFieldProps('tx_cep')}
                      />
                      {formik.touched.tx_cep && formik.errors.tx_cep && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tx_cep}</div>
                        </div>
                      )}
                    </div>
                  
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className='required'>Estado / UF</span>
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                          type='text'
                          maxLength={30}
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Estado'
                          {...formik.getFieldProps('tx_estado')}
                        />
                        {formik.touched.tx_estado && formik.errors.tx_estado && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.tx_estado}</div>
                          </div>
                        )}
                    </div>

                    <div className='col-lg-3 fv-row'>
                      <input
                        type='text'
                        maxLength={2}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='UF'
                        {...formik.getFieldProps('tx_sigla')}
                      />
                      {formik.touched.tx_sigla && formik.errors.tx_sigla && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tx_sigla}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className='required'>País</span>
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                          type='text'
                          maxLength={30}
                          className='form-control form-control-lg form-control-solid'
                          placeholder='País'
                          {...formik.getFieldProps('tx_pais')}
                        />
                        {formik.touched.tx_pais && formik.errors.tx_pais && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.tx_pais}</div>
                          </div>
                        )}
                    </div>

                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className='required'>CPF</span>
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        maxLength={20}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='CPF'
                        {...formik.getFieldProps('tx_cpf_presidente')}
                      />
                      {formik.touched.tx_cpf_presidente && formik.errors.tx_cpf_presidente && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tx_cpf_presidente}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>

              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className='required'>Email / Telefone</span>
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                          type='text'
                          maxLength={50}
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Email'
                          {...formik.getFieldProps('tx_email_presidente')}
                        />
                        {formik.touched.tx_email_presidente && formik.errors.tx_email_presidente && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.tx_email_presidente}</div>
                          </div>
                        )}
                    </div>

                    <div className='col-lg-3 fv-row'>
                      <input
                        type='text'
                        maxLength={20}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='(38) 9999-9999'
                        {...formik.getFieldProps('tx_telefone_presidente')}
                      />
                      {formik.touched.tx_telefone_presidente && formik.errors.tx_telefone_presidente && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tx_telefone_presidente}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className='required'>Nome / Ministro</span>
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                          type='text'
                          maxLength={100}
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Nome do presidente'
                          {...formik.getFieldProps('tx_nome_presidente')}
                        />
                        {formik.touched.tx_nome_presidente && formik.errors.tx_nome_presidente && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.tx_nome_presidente}</div>
                          </div>
                        )}
                    </div>

                    <div className='col-lg-3 fv-row'>
                      <input
                        type='text'
                        maxLength={20}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Ministro'
                        {...formik.getFieldProps('tx_ministro_presidente')}
                      />
                      {formik.touched.tx_ministro_presidente && formik.errors.tx_ministro_presidente && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.tx_ministro_presidente}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>   

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='reset' className='btn btn-light btn-active-light-primary me-2' disabled={!isTouched()} onClick={reset}>Descartar</button>
              <button type='submit' className='btn btn-primary me-2' disabled={loading || !isTouched()}>
                {!loading ? ('Salvar Cadastro') : '' }
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Por favor aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button type='button' className='btn btn-primary me-2' onClick={voltar}>Voltar</button>
            </div>
          </form>
        </div>
      </div>

      <ToastContainer autoClose={2000} />
    </>
  )
}

export {DadosGeraisTemploFormNew}
