import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import { AuthorizedFunction } from '../../../../app/modules/auth/components/AuthorizedFunction'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/app/dashboard' />
      {/* <MenuItem title='Admin' to='/admin' /> */} 
       {/* PAGES 
      <MenuInnerWithSub
        title='Cadastro'
        to='/cadastro'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
          
        <MenuInnerWithSub
          title='Pages'
          to='/cadastro/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/cadastro/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/cadastro/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/cadastro/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/cadastro/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/cadastro/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/cadastro/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/cadastro/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/cadastro/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/cadastro/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>
      
         ACCOUNT 
        
        <MenuInnerWithSub
          title='Ficha'
          to='/enroll/medium'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/app/enroll/medium/12345/detalhe' title='Detalhe' hasBullet={true} />
          <MenuItem to='/app/enroll/medium/12345/alteracao' title='Alteração' hasBullet={true} />
        </MenuInnerWithSub>

      

        ERRORS
        <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>

        Widgets 
        <MenuInnerWithSub
          title='Widgets'
          to='/cadastro/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/cadastro/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/cadastro/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/cadastro/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/cadastro/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/cadastro/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/cadastro/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

    */}
{/* 
      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='message-text-2'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        
        
        {AuthorizedFunction(['operacional-devas']) && <MenuItem icon='shield-tick' to='/app/management/medium/search' title='Médiuns' />}
        
      </MenuInnerWithSub>
      */}

 {/* 
      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub>
*/}
    </>
  )
}
