/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'

const Item1: FC = () => {
  return (
    <div className='timeline-item'>
      <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
        <div className='symbol-label bg-light'>
          <KTIcon iconName='message-text-2' className='fs-2 text-gray-500' />
        </div>
      </div>

      <div className='timeline-content mb-10 mt-n1'>
        <div className='pe-3 mb-5'>
          <div className='fs-5 fw-bold mb-2'>
          Adjuntos Adejã:
          </div>

          <div className='d-flex align-items-center mt-1 fs-6'>
            <div className='text-muted me-2 fs-7'>2º filho de Devas</div>

            <div
              className='symbol symbol-circle symbol-25px'
              data-bs-toggle='tooltip'
              data-bs-boundary='window'
              data-bs-placement='top'
              title='Força Decrecente'
            >
              <img src={toAbsoluteUrl('/media/adjunto/jumba.jpg')} alt='img' />
            </div>
          </div>
        </div>

        <div className='overflow-auto pb-5'>
          <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-300px px-7 py-3 mb-5'>
          <div className='mb-5 pe-3'>
          <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
            Reunião com o Povo adejã:
          </a>

          <div className='d-flex align-items-center mt-1 fs-6'>
            <div className='text-muted me-2 fs-7'>Último domingo do Mês</div>
            <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
              <div className='symbol symbol-circle symbol-50px'>
                <img src={toAbsoluteUrl('/media/adjunto/cruz.jpg')} alt='img' />
              </div>

              <div className='symbol symbol-circle symbol-50px'>
                <img src={toAbsoluteUrl('/media/adjunto/apara.jpg')} alt='img' />
              </div>

            </div>

            <div
              className='symbol symbol-circle symbol-50px'
              data-bs-toggle='tooltip'
              data-bs-boundary='window'
              data-bs-placement='top'
              title='Benção do Ministro Adejã'
            >
              
              <img src={toAbsoluteUrl('/media/adjunto/adeja.jpg')} alt='img' />
              
            </div>
          </div>
        </div>

           

            
          </div>

          <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-400px px-7 py-3 mb-5'>
            <a href='#' className='fs-5 text-dark text-hover-primary fw-bold w-375px min-w-200px'>
              Reunião do povo Adejã
            </a>
            <span className='badge badge-light text-muted'>Último domingo do mês</span>           

            <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
              <div className='symbol symbol-circle symbol-25px'>
                <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='img' />
              </div>

              <div className='symbol symbol-circle symbol-25px'>
                <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
              </div>

            </div>

           

            
          </div>
        </div>
      </div>
    </div>
  )
}

export {Item1}
