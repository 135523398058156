import {FC} from 'react'
import { Templo } from '../../core/_models'

type Props = {
  templo?: Templo
}
let sufixTemple = ' DO AMANHECER';

const TemploCell: FC<Props> = ({templo}) => (
  <> 
   
      <div className='badge badge-light-success fw-bolder'>  
      
      {templo && templo.id_templo == 1  ? (
          templo.tx_cidade
        ) : (
          templo?.tx_cidade + ' - ' + templo?.tx_sigla + ' | ' + templo?.tx_ministro_presidente + sufixTemple
       )}

      </div>
    
  </>
)

export {TemploCell}
