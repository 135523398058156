import {FC, useMemo} from 'react'
import {ID} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/TemplosListViewProvider'
import { useNavigate } from 'react-router-dom'
import { useQueryResponse } from '../../core/QueryResponseTemplosProvider'

type Props = {
  id: ID
}

const IdTemploCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView();
  const navigate = useNavigate()
  const {refetch} = useQueryResponse()

  const detalhe = (withRefresh?: boolean) => {
    setItemIdForUpdate(id)
    if (withRefresh) {
      refetch()
    }
    let view_path = `/app/enroll/templo/${id}/detalhe`; 
    navigate(view_path);
  }

  return (
    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'  onClick={() => detalhe()}>
          {id}
        </a>
    </div>
  )
}

export {IdTemploCell}
