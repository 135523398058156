import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { TemplosListWrapper } from './users-list/TemplosList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Templos',
    path: '/app/management/templo/search',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PesquisaTemploPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Pesquisa de templos</PageTitle>
              <TemplosListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='search' />} />
    </Routes>
  )
}

export default PesquisaTemploPage
