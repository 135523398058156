import { useKeycloak } from '@react-keycloak/web';

export function AuthorizedFunction(roles: any) {
    const arr_roles:[] = roles;
    const {keycloak, initialized} = useKeycloak();
    //console.log('roles:', arr_roles)
    const isAutherized = () => {
        if (keycloak && roles) {
            return arr_roles.some(r => {
                const realm =  keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    }

    return isAutherized();
}