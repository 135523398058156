import {FC} from 'react'
import moment from "moment"
import { newDateUserTimezoneOffset } from '../../../../../../../_metronic/helpers';

const SPACED_DATE_FORMAT = "DD/MM/YYYY";

type Props = {
  date: string
}

const MediumDateFieldCell: FC<Props> = ({date}) => (
  <div className='badge badge-light fw-bolder'>{ date !== null ? moment(newDateUserTimezoneOffset(date)).format(SPACED_DATE_FORMAT): 'Não informado'}</div>
)

export {MediumDateFieldCell}
