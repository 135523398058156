import {useListView} from '../../core/TemplosListViewProvider'
import {TemplosListGrouping} from './TemplosListGrouping'
import {GenericListSearchComponent} from './GenericListSearchComponent'
import { TemplosListToolbar } from './TemplosListToolbar'

type Props = {
  termo: string,
  add_path: string
}

const TemplosListHeader: React.FC<Props> = ({termo, add_path}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <GenericListSearchComponent termo = {termo} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TemplosListGrouping /> : <TemplosListToolbar add_path={add_path} />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TemplosListHeader}
